import { NavLink } from 'react-router-dom';

import './LogoSection.css';
import Logo from '../../logo/Logo';

function LogoSection() {
    return (
        <NavLink to="/" className="custom-footer-link">
            <Logo />
        </NavLink>
    );
}

export default LogoSection;