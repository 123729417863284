// Import the functions you need from the SDKs you need
// import axios from "axios";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  // apiKey: process.env.REACT_PUBLIC_API_KEY,
  // authDomain: process.env.REACT_PUBLIC_AUTH_DOMAIN,
  // projectId: process.env.REACT_PUBLIC_PROJECT_ID,
  // storageBucket: process.env.REACT_PUBLIC_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_PUBLIC_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_PUBLIC_APP_ID,
  // measurementId: process.env.REACT_PUBLIC_MEASUEMENT_ID,
  apiKey: "AIzaSyCbtZpUDMToZMefvdNKLS_23TwXKEzi2mQ",
  authDomain: "pizzaservice2023.firebaseapp.com",
  projectId: "pizzaservice2023",
  storageBucket: "pizzaservice2023.appspot.com",
  messagingSenderId: "986871090885",
  appId: "1:986871090885:web:974b4c8384a8a26a0aaaac",
  measurementId: "G-WEHLBWE80E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// const messaging = getMessaging(app);
// export { messaging };

// export const requestForToken = () => {
//   return getToken(messaging, {
//     vapidKey:
//       "BGkGZ49OB2hJBtGib9C-cdwaHrzR6v3bQ2Fa4gJo1lkQFLRIC5bUvco23S7kZ-K-EY1FDM8c-GjVCdzUa2u10QQ",
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//         localStorage.setItem("FCM_Token", currentToken);
//         // Perform any other neccessary action with the token
//         const user = JSON.parse(localStorage.getItem("user"))
//           ? JSON.parse(localStorage.getItem("user"))
//           : "";
//         let machineId = localStorage.getItem("MachineId");
//         if (!machineId) {
//           machineId = crypto.randomUUID();
//           localStorage.setItem("MachineId", machineId);
//         }
//         axios
//           .post(
//             process.env.REACT_APP_API + "notifications/update_fcm",
//             {
//               fcm_token: currentToken,
//               device_id: machineId,
//             },
//             {
//               headers: {
//                 Authorization: `Bearer ${user?.token}`,
//               },
//             }
//           )
//           .then((res) => {
//             // console.log(res);
//             return res;
//           });
//         // return messaging.subscribeToTopic("news");
//       } else {
//         // Show permission request UI
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//     });
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//       if (payload.data.topic === "user") {
//         alert("You have received a notification for the topic: user");
//       }
//     });
//   });

// const analytics = getAnalytics(app);
