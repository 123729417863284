import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setDeliveryInFees } from "./orderSlice";

const initialState = {
  /* show is the primary state for Content Order Modal for small screens. */
  show: false,
  /* showOrderMethods designates whether or not to display the modal of Order Methods. */
  showOrderMethods: false,
  /* selectedOrderMethodId holds the id of the order method chosen by the user, in order to highlight them. */
  selectedOrderMethodId: localStorage.getItem("orderType")
    ? localStorage.getItem("orderType")
    : "",
  /* isSidebarShown designates whether or not to show the sidebar in Our Branches page. */
  isSidebarShown: false,
  /* confirmItemChange designates whether or not to show the modal that prompts the user to confirm or cancel
  the change of the selected menu item when their changes are not submitted. */
  confirmItemChange: false,
  /* showNavSidebar designates whether or not the navigation sidebar for small screens is displayed. */
  showNavSidebar: false,
};

export const setSelectedOrderMethod = createAsyncThunk(
  "showModal/setSelectedOrderMethod",
  async (method, { dispatch }) => {
    dispatch(setDeliveryInFees(method));
    return method;
  }
);

export const showModalSlice = createSlice({
  name: "showModal",
  initialState,
  reducers: {
    /* showSidebar sets state.isSidebarShown to true. */
    showSidebar: (state) => {
      state.isSidebarShown = true;
    },

    /* showSidebar sets state.isSidebarShown to false. */
    hideSidebar: (state) => {
      state.isSidebarShown = false;
    },

    /* displayModal controls whether to show Content Order modal or Order Methods modal based on action.payload
      whether it's undefined or not. */
    displayModal: (state, action) => {
      if (action.payload !== undefined) {
        if (
          action.payload === "Delivery" ||
          action.payload === "Pickup" ||
          action.payload === "On the spot"
        ) {
          state.selectedOrderMethodId = action.payload;
        }
        state.showOrderMethods = true;
      } else {
        state.show = true;
      }
    },

    /* hideModal controls whether to hide Content Order modal or Order Methods modal based on action.payload
      whether it's undefined or not. */
    hideModal: (state, action) => {
      if (action.payload === "hide order methods") {
        state.showOrderMethods = false;
      } else {
        state.show = false;
      }
    },

    /* setConfirmItemChange changes the state of confirmItemChange based on action.payload which is a boolean. */
    setConfirmItemChange: (state, action) => {
      state.confirmItemChange = action.payload;
    },

    /* displayNavSidebar changes the state of the navigation sidebar depending on action.payload which is a boolean.
      if action.payload is undefined or null, the state is toggled. */
    displayNavSidebar: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.showNavSidebar = action.payload;
      } else {
        state.showNavSidebar = !state.showNavSidebar;
      }
    },

    // setSelectedOrderMethod: (state, action) => {
    //   state.selectedOrderMethodId = action.payload;
    // }
  },

  extraReducers: (builder) => {
    builder.addCase(setSelectedOrderMethod.fulfilled, (state, action) => {
      state.selectedOrderMethodId = action.payload;
    });
  },
});

export const {
  displayModal,
  hideModal,
  showSidebar,
  hideSidebar,
  setConfirmItemChange,
  displayNavSidebar,
  // setSelectedOrderMethod,
} = showModalSlice.actions;

export default showModalSlice.reducer;
