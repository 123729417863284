import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import i18n from "../../i18n";
import { toast } from "react-toastify";

import PizzaSmall from "../../assets/restaurant-page/content-info/pizza-big.png";
import Fanta from "../../assets/restaurant-page/content-info/fanta.svg";
import Cocacola from "../../assets/restaurant-page/content-info/cocacola.svg";
import Pepsi from "../../assets/restaurant-page/content-info/pepsi.svg";

import {
  getItemOptions,
  setCheckedItemsRef,
  setOrderPlaced,
  setValuesRef,
} from "./orderSlice";
import { hideModal } from "./showModalSlice";
import { addRestaurantInfoSecondaryImage } from "./restaurantsSlice";

const initialState = {
  /* menu will hold the menu for a restaurant after dispatching a request to get it for that specific restaurant */
  menu: [],
  /* categories will hold the categories for a restaurant after dispatching a request to get it for that specific restaurant */
  categories: [],
  /* menuItem specifies which menu item is currently selected, useful for storing the currently selected item from
    the menu in Panels, and all its related data. */
  menuItem: null,
  /* menuItemOptions stores the options regarding the selected menu item, needed for storing, loading, and modifying
    those options based on user's interactions. */
  menuItemOptions: null,
  /* selectedItemId stores the id of the currently selected item, useful for handling items selection and their
    options display. */
  selectedItemId: "",
  /* targetedItemId stores the next item to be selected, so if there was some other one selected with some changes made
    that the user didn't submit, and we asked for their conformation and they discard those changes, we can correctly
    change selection to the new item with no issues. */
  targetedItemId: "",
  /* showFurtherInfo is used to control the modal holding extra information about a menu item. */
  showFurtherInfo: false,
  /* targetedItemCategory holds the category of the targeted item that the user selected to display its info.
    this is needed to search for the item in the menu */
  targetedItemCategory: "",
  /* isLoading is a flag used to determine that the data is being fetched, useful for spinners */
  isLoading: false,
  /* isLoadingChoices is a flag used to determine that the data is being fetched, useful for spinners */
  isLoadingChoices: false,
  isVegan: false,
  menuItemsStatus: "idle",
  menuCategoriesStatus: "idle",
};

const url = process.env.REACT_APP_API;

/* getMenuCategories is used to dispatch a request to the backend to get the categories for the selected restaurant */
export const getMenuCategories = createAsyncThunk(
  "menuItems/getMenuCategories",
  async (params, { getState, dispatch, rejectWithValue }) => {
    // const lang = getState().lang.lang;
    const id = getState().restaurantsDetails.selectedRestaurantId;

    try {
      const response = await axios.get(
        `${url}restaurants/${id}?relation[0]=menus.categories.items.variations&is_vegan=${
          params?.isVegan ? params?.isVegan : 0
        }`,

        {
          headers: {
            "X-localization": params?.lang,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        dispatch(
          addRestaurantInfoSecondaryImage(response.data.data[0].image_info)
        );
      }

      return {
        response: response.data.data[0],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/* getItemChoices is used to send a request to the backend to get the default choices of a selected item
the parameter "item" has the needed info to complete that request, if it has "update" flag, then the user
has chosen that item from OrderPage and wants to modify their choices. */
export const getItemChoices = createAsyncThunk(
  "menuItems/getItemChoices",
  async (item, { getState, dispatch, rejectWithValue }) => {
    // const lang = getState().lang.lang;
    let orders = [];
    let oldItem = null;
    if (item.update) {
      orders = getState().order.orders;
      oldItem = orders.find(({ id }) => id === item.item_id);
    }
    try {
      const response = await axios.get(
        `${url}restaurants/menu/item/${item.id}`,
        {
          headers: {
            "X-localization": item?.lang,
          },
        }
      );

      const data = response.data.data;

      const tempValues = [];
      const tempCheckedItems = [];
      const sizes = data.variation.map(
        ({ id, title, price, price_after_vat, choice_Groups }) => {
          return {
            id: id,
            label: title,
            price: price,
            price_after_vat: price,
            selected: item?.info ? title === item?.info.size : false,
            extras: choice_Groups.map((choiceGroup) => {
              return {
                id: choiceGroup.id,
                name: choiceGroup.name,
                minRequired: choiceGroup.minimum_required_options,
                maxRequired: choiceGroup.maximum_required_options,
                choices: choiceGroup.Choice_Items.map(({ id, name, price }) => {
                  if (item?.info) {
                    if (item?.info.extras.includes(name) && item?.info.size) {
                      tempValues.push(`${name}_${price}`);
                      tempCheckedItems.push(
                        `choiceGroup_${choiceGroup.name}-${name}-${price}`
                      );
                    }
                  }
                  return {
                    id: id,
                    label: name,
                    name: name,
                    price: price,
                    price_after_vat: price,
                    selected: item?.info
                      ? item?.info.extras.includes(name)
                      : false,
                  };
                }),
              };
            }),
          };
        }
      );

      const tempOptions = {
        suggestions: [
          {
            imageSrc: Fanta,
            imageAlt: "Fanta",
            price: 20,
            title: "Fanta",
            count: 0,
          },
          {
            imageSrc: Cocacola,
            imageAlt: "Cocacola",
            price: 20,
            title: "Cocacola",
            count: 0,
          },
          {
            imageSrc: Pepsi,
            imageAlt: "Pepsi",
            price: 20,
            title: "Pepsi",
            count: 0,
          },
          {
            imageSrc: Fanta,
            imageAlt: "Fanta",
            price: 20,
            title: "Fanta2",
            count: 0,
          },
        ],
        instructions: oldItem ? oldItem.specialInstructions : "",
        sizes: sizes,
      };

      let states = null;
      if (item?.update) {
        states = {
          size: oldItem.size,
          count: oldItem.orderCount,
          bill: oldItem.totalBill,
        };
      }

      if (item?.info) {
        dispatch(setValuesRef(tempValues));
        dispatch(setCheckedItemsRef(tempCheckedItems));
      }
      await dispatch(
        getItemOptions({ item: item, itemOptions: tempOptions, states: states })
      );

      return {
        options: tempOptions,
      };
    } catch (error) {
      dispatch(setSelectedItemId(""));
      dispatch(hideModal());
      return rejectWithValue(error.response.data);
    }
  }
);

export const menuItemsSlice = createSlice({
  name: "menuItems",
  initialState,
  reducers: {
    /* getMenuItem is used to correctly retrieve the selected item from the menu we have
        based on the specified action.payload.title which represents the category, and then on action.payload.id
        which represents the item id, all if it was selected according to action.payload.selected
        else, it would be set to null so no options are displayed and we remove the selection state of the item.
        It also sets the menuItemOptions to be used for later.
        NOTE: this will either become a get request "asyncThunkReducer" or remains as it is after we retrieve
        all of the menu on first load. */
    getMenuItem: (state, action) => {
      if (action.payload.selected) {
        const menu = JSON.parse(JSON.stringify(state.menu));
        const items = menu.find(({ title }) => title === action.payload.title);
        state.menuItem = items.content.find(
          ({ id }) => id === action.payload.id
        );
        menu.forEach(({ content }) => {
          content.forEach(({ id }, index) => {
            if (id === action.payload.id) {
              content[index].selected = true;
            } else {
              content[index].selected = false;
            }
          });
        });
        state.menu = JSON.parse(JSON.stringify(menu));
        state.menuItemOptions = state.menuItem?.options;
      } else {
        state.menuItem = null;
        state.menuItemOptions = null;
        state.menu.forEach(({ content }) => {
          content.forEach((_, index) => {
            content[index].selected = false;
          });
        });
      }
    },

    /* setSelectedItemId changes the selectedItemId to the id of the currently selected item.
        action.payload holds the id of the selected item. */
    setSelectedItemId: (state, action) => {
      state.selectedItemId = action.payload;
    },

    /* displayFurtherInfo changes the state of showFurtherInfo based on action.payload which is a boolean,
        where true means display the modal with the info, and false otherwise. */
    displayFurtherInfo: (state, action) => {
      state.showFurtherInfo = action.payload;
    },

    /* setTargetedItemId changes the targetedItemId to the id of the next item to be selected.
        action.payload holds the id of the next item, waiting for user confirmation to make that item selected. */
    setTargetedItemInfo: (state, action) => {
      state.targetedItemId = action.payload.id;
      state.targetedItemCategory = action.payload.category;
    },

    resetMenuItems: (state) => {
      state.menu = [];
      state.categories = [];
      state.menuItem = null;
      state.menuItemOptions = null;
      state.selectedItemId = "";
      state.targetedItemId = "";
    },
    setIsVegan: (state) => {
      state.isVegan = !state.isVegan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenuCategories.fulfilled, (state, action) => {
        if (
          action.payload.response.menu_categories &&
          action.payload.response.menu_categories.length > 0 &&
          action.payload.response.menu_categories[0]?.categories
        ) {
          toast.warning(i18n.t("There's no available menu at the moment"));
        } else {
          toast.warning(i18n.t("There's no available menu at the moment"));
        }
        state.isLoading = false;
        state.menuCategoriesStatus = "succeeded";
        state.menuItemsStatus = "succeeded";
      })
      .addCase(getMenuCategories.pending, (state) => {
        state.isLoading = true;
        state.menuCategoriesStatus = "loading";
        state.menuItemsStatus = "loading";
      })
      .addCase(getMenuCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.menuCategoriesStatus = "rejected";
        state.menuItemsStatus = "rejected";
      })

      .addCase(getItemChoices.fulfilled, (state, action) => {
        state.menuItemOptions = action.payload.options;
        state.isLoadingChoices = false;
      })
      .addCase(getItemChoices.pending, (state) => {
        state.isLoadingChoices = true;
      })
      .addCase(getItemChoices.rejected, (state, action) => {
        state.isLoadingChoices = false;
        toast.error(i18n.t("Something went wrong"));
      });
  },
});

export const {
  getMenuItem,
  setSelectedItemId,
  displayFurtherInfo,
  setTargetedItemInfo,
  resetMenuItems,
  setIsVegan,
} = menuItemsSlice.actions;

export default menuItemsSlice.reducer;
