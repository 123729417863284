
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './ConfirmationModal.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function ConfirmationModal({show=false, message, handleConfirm, handleCancel, confirmText='Discard', cancelText='Cancel', confirmVariant='danger', cancelVariant='secondary'}) {
    const { t } = useTranslation();
    const { isProcessing, isChangingStatus } = useSelector(state => state.userInfo);

    return (
        <Modal show={show}>
            <Modal.Body>
                {t(message)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={cancelVariant} onClick={handleCancel}>
                    {
                        isChangingStatus ?
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="custom-sign-in-spinner"
                            />
                        :
                            null
                    }
                    {t(cancelText)}
                </Button>
                <Button variant={confirmVariant} onClick={handleConfirm}>
                    {
                        isProcessing ?
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="custom-sign-in-spinner"
                            />
                        :
                            null
                    }
                    {t(confirmText)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;