import { createSlice } from "@reduxjs/toolkit";

const lang =
  localStorage.getItem("lang") !== null
    ? JSON.parse(localStorage.getItem("lang"))
    : "fi";

const initialState = {
  lang,
  // currentLang: 'en',
};

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    switchLang(state, action) {
      state.lang = action.payload;
      // state.currentLang = action.payload;
      localStorage.setItem("lang", JSON.stringify(state.lang));
    },
  },
});

export const { switchLang } = langSlice.actions;

export default langSlice.reducer;
