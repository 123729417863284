import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import i18n from "../../i18n";

import { toast } from "react-toastify";
import { getRestaurantReviews } from "./restaurantsSlice";

const url = process.env.REACT_APP_API;

const initialState = {
  rate: 1,
  review: "",
  date: null,
  reset: false,
};

export const addReview = createAsyncThunk(
  "review/addReview",
  async (reviewInfo, { getState, dispatch }) => {
    const token = JSON.parse(localStorage.getItem("user")).token;
    const data = {
      restaurant_id: getState().restaurantsDetails.selectedRestaurantId,
      review: reviewInfo.review,
      rating: reviewInfo.rate,
    };
    const response = await axios.post(`${url}restaurants/review`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await dispatch(getRestaurantReviews());
    return {
      response: response.data,
    };
  }
);

export const addReviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addReview.fulfilled, (state) => {
        state.isLoading = false;
        toast.success(
          i18n.t("Submission was successful. Thank you for your feedback!")
        );
        state.reset = true;
      })
      .addCase(addReview.pending, (state) => {
        state.isLoading = true;
        state.reset = false;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false;
        let message = "";
        if (action.payload.response.message === "Unauthenticated.") {
          message = i18n.t("You must be logged in before submitting a review");
        } else {
          message = i18n.t("Something went wrong");
        }
        toast.error(i18n.t(message));
      });
  },
});

export const {} = addReviewSlice.actions;

export default addReviewSlice.reducer;
