import { configureStore } from "@reduxjs/toolkit";

import showModalReducer from "../slices/showModalSlice";
import menuItemsReducer from "../slices/menuItemsSlice";
import orderReducer from "../slices/orderSlice";
import restaurantsReducer from "../slices/restaurantsSlice";
import checkoutReducer from "../slices/checkoutSlice";
import userInfoReducer from "../slices/userInfoSlice";
import langReducer from "../slices/langSlice";
import signInReducer from "../slices/signInSlice";
import addReviewReducer from "../slices/addReviewSlice";
import footerReducer from "../slices/footerSlice";
import homeReducer from "../slices/homeSlice";
import contactReducer from "../slices/contactSlice";
import notificationsReducer from "../slices/notificationsSlice";
import privacyTermsReducer from "../slices/privacyTermsSlice";
import couponReducer from "../slices/couponsSlice";
import myOrderReducer from "../slices/myOrderSlice";

export const store = configureStore({
  reducer: {
    showModal: showModalReducer,
    menuItems: menuItemsReducer,
    order: orderReducer,
    restaurantsDetails: restaurantsReducer,
    checkout: checkoutReducer,
    userInfo: userInfoReducer,
    lang: langReducer,
    footer: footerReducer,
    signIn: signInReducer,
    review: addReviewReducer,
    home: homeReducer,
    contact: contactReducer,
    notifications: notificationsReducer,
    privacyTerms: privacyTermsReducer,
    coupon: couponReducer,
    myOrder: myOrderReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
