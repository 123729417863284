import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import i18n from "../../i18n";
import { setDefaults, fromLatLng } from "react-geocode";

import { toast } from "react-toastify";

import RestaurantImage from "../../assets/restaurant-image.png";

import { setSelectedOrderMethod } from "./showModalSlice";

setDefaults({
  key: `${process.env.REACT_APP_API_KEY}`, // Your API key here.
  language: "en", // Default language for responses.
  region: "eu", // Default region for responses.
});

const initialState = {
  /* restaurants hold all the restaurants available */
  restaurants: [],
  /* restaurantsNearby holds the filtered restaurants "close to the user's location" if order method
    is delivery, else it stores them all */
  restaurantsNearby: [],
  /* selectedRestaurantId holds the id of the restaurant selected by the user, in order to display that branch's
    menu later. */
  selectedRestaurantId: "",
  /* selectedRestaurantInfo holds the details of the restaurant selected by the user. */
  selectedRestaurantInfo: null,
  /* userAddress holds the address (required) and the apartment (optional if not needed) of the user
    whether they're set manually by them or through geolocation api. */
  userAddress: { address: "", apartment: "", coordinates: "" },
  /* spinnerActive designates whether the spinner is currently running or not. */
  spinnerActive: false,
  /* restaurantReviews holds the reviews submitted by the users which are related to the selected restaurant */
  restaurantReviews: [],
  /* isLoading is a flag representing that data is being fetched, used for spinners */
  isLoading: false,
  /* canChangeOrderMethod is a flag representing if the order method in OrderMethodsModal can be changed to delivery */
  canChangeOrderMethod: true,
  getAllRestaurantsStatus: "idle",
  getNearbyRestaurantsStatus: "idle",
};

export const getAllRestaurants = createAsyncThunk(
  "restaurantsDetails/getAllRestaurants",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}restaurants`,
        {
          headers: {
            "X-localization": lang,
          },
        }
      );
      return {
        restaurants: response.data.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRestaurantReviews = createAsyncThunk(
  "restaurantsDetails/getRestaurantReviews",
  async (_, { getState, rejectWithValue }) => {
    const restaurantId = getState().restaurantsDetails?.selectedRestaurantId;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}restaurants/${restaurantId}/reviews`
      );
      return {
        response: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNearbyRestaurants = createAsyncThunk(
  "restaurantsDetails/getNearbyRestaurant",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const ordersNotEmpty = getState().order.orders.length !== 0;
    const orderMethod = getState().showModal.selectedOrderMethodId;
    const selectedRestaurantId =
      getState().restaurantsDetails.selectedRestaurantId;
    const restaurants = [];
    const coordinates = [];
    const origins =
      getState().restaurantsDetails.userAddress.coordinates.replace(",", "%2C");
    getState().restaurantsDetails.restaurants.forEach((restaurant) => {
      if (restaurant.lat !== null && restaurant.long !== null) {
        const coords = `${restaurant.lat},${restaurant.long}`;
        const formattedCoords = coords.replace(",", "%2C");
        const coordsExist = coordinates.findIndex(
          (coords) => coords === formattedCoords
        );
        if (coordsExist === -1) {
          restaurants.push({ ...restaurant, coordinates: coords });
          coordinates.push(formattedCoords);
        }
      }
    });

    const destinations = coordinates.join("%7C");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}googleMap?origin=${origins}&destination=${destinations}`
      );

      const acceptedRestaurants = [];

      const results = response?.data?.data?.rows[0]?.elements;
      if (selectedRestaurantId && ordersNotEmpty) {
        const restaurantIndex = restaurants.findIndex(
          ({ id }) => id === selectedRestaurantId
        );
        const durationText = results[restaurantIndex]?.duration?.text;
        const distanceText = results[restaurantIndex]?.distance?.text;
        if (orderMethod === "Delivery") {
          const distance = Number(distanceText?.split(" ")[0].replace(",", ""));
          if (distance > 7) {
            toast.error(
              i18n.t(
                "Currently selected restaurant is far from your location for delivery"
              )
            );
            await dispatch(setSelectedOrderMethod("Pickup"));
          }
        }
        const acceptedRestaurant = {
          ...restaurants[restaurantIndex],
          duration: durationText,
          distance: distanceText,
        };
        acceptedRestaurants.push(acceptedRestaurant);
      } else {
        for (let index = 0; index < results.length; index += 1) {
          const durationText = results[index]?.duration?.text;
          const distanceText = results[index]?.distance?.text;

          if (orderMethod === "Delivery") {
            const distance = Number(distanceText?.split(" ")[0]);
            if (distance <= 7) {
              const acceptedRestaurant = {
                ...restaurants[index],
                duration: durationText,
                distance: distanceText,
              };
              acceptedRestaurants.push(acceptedRestaurant);
            }
          } else {
            const acceptedRestaurant = {
              ...restaurants[index],
              duration: durationText,
              distance: distanceText,
            };
            acceptedRestaurants.push(acceptedRestaurant);
          }
        }
      }

      return {
        acceptedRestaurants: acceptedRestaurants,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/* convertReviewTime formats the submit-time of the review, to be displayed according to the design */
const convertReviewTime = (time) => {
  const createdAt = new Date(time);
  const today = new Date();
  const years = today.getFullYear() - createdAt.getFullYear();
  if (years > 0) {
    return `${years} ${years === 1 ? i18n.t("year") : i18n.t("years")} ${i18n.t(
      "ago"
    )}`;
  } else {
    const difference = new Date(today - createdAt);
    const months = difference.getMonth();
    if (months > 0) {
      return `${months} ${
        months === 1 ? i18n.t("month") : i18n.t("months")
      } ${i18n.t("ago")}`;
    } else {
      const days = difference.getDate();
      if (days > 0) {
        return `${days} ${days === 1 ? i18n.t("day") : i18n.t("days")} ${i18n.t(
          "ago"
        )}`;
      } else {
        const hours = difference.getHours();
        if (hours > 0) {
          return `${hours} ${
            hours === 1 ? i18n.t("hour") : i18n.t("hours")
          } ${i18n.t("ago")}`;
        } else {
          const minutes = difference.getMinutes();
          if (minutes < 5) {
            return i18n.t("Just now");
          } else {
            return `${minutes} ${i18n.t("minutes")} ${i18n.t("ago")}`;
          }
        }
      }
    }
  }
};

/* serverError is a function formatting error messages for when a request is rejected */
const serverError = (word) => {
  toast.error(
    `${i18n.t("Unable to fetch")} ${i18n.t(word)}, ${i18n.t(
      "please try again"
    )}`
  );
};

export const restaurantsSlice = createSlice({
  name: "restaurantsDetails",
  initialState,
  reducers: {
    /* setSelectedRestaurantId changes the id of the selected restaurant based on action.payload
        after a user click on a restaurant. */
    setSelectedRestaurantId: (state, action) => {
      const restaurants = action.payload.nearby
        ? JSON.parse(JSON.stringify(state.restaurantsNearby))
        : JSON.parse(JSON.stringify(state.restaurants));

      if (action.payload.id) {
        let restaurantIndex = null;
        restaurants.forEach(({ id }, index) => {
          if (id === +action.payload.id) {
            restaurants[index].restaurantSelected = true;
            restaurantIndex = index;
          } else {
            restaurants[index].restaurantSelected = false;
          }
        });
        state.selectedRestaurantId = action.payload.id;
        state.selectedRestaurantInfo = { ...restaurants[restaurantIndex] };

        if (!state.selectedRestaurantInfo?.location?.address) {
          try {
            const response = fromLatLng(
              state.selectedRestaurantInfo.location.lat,
              state.selectedRestaurantInfo.location.lng
            );

            state.selectedRestaurantInfo.location.address =
              response?.results[0]?.formatted_address; // Provide a default value if formatted_address is undefined
          } catch (error) {
            console.log(error);
            state.selectedRestaurantInfo.location.address = ""; // Handle errors gracefully by setting address to an empty string
          }
        }
        restaurants[restaurantIndex].location.address =
          state.selectedRestaurantInfo.location.address;

        if (action.payload.nearby) {
          state.restaurantsNearby = [...restaurants];
        } else {
          state.restaurants = [...restaurants];
        }
      } else {
        state.selectedRestaurantId = "";
        restaurants.forEach((_, index) => {
          restaurants[index].restaurantSelected = false;
        });
      }
    },

    /* activateSpinner sets the spinner animation to true. */
    activateSpinner: (state, action) => {
      if (action.payload !== undefined || action.payload !== null) {
        state.spinnerActive = action.payload;
      } else {
        state.spinnerActive = true;
      }
    },

    /* setUserAddress stores the location and the apartment of the user, specified by action.payload
        then deactivates the spinner. */
    setUserAddress: (state, action) => {
      if (action.payload.success) {
        state.userAddress = {
          address: action.payload.address,
          apartment: action.payload.apartment,
          coordinates: action.payload.coordinates,
        };
      } else {
        state.spinnerActive = false;
      }
    },

    /* addRestaurantInfoSecondaryImage adds to the restaurant's info an image to be displayed in BasicInfo section */
    addRestaurantInfoSecondaryImage: (state, action) => {
      state.selectedRestaurantInfo = {
        ...JSON.parse(JSON.stringify(state.selectedRestaurantInfo)),
        imageInfo: action.payload,
      };
    },

    resetRestaurants: (state) => {
      state.selectedRestaurantId = "";
      state.selectedRestaurantInfo = null;
      state.restaurantReviews = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRestaurants.fulfilled, (state, action) => {
        state.getAllRestaurantsStatus = "succeeded";
        const restaurants = [];
        action.payload.restaurants.forEach((restaurant) => {
          if (restaurant.image === null) {
            restaurant.image = RestaurantImage;
          }
          restaurant.restaurantSelected = false;
          let location = {
            address: "",
            lat: restaurant.lat ? restaurant.lat : "60.17197366879364",
            lng: restaurant.long ? restaurant.long : "24.941366853730095",
          };

          restaurant.location = { ...location };
          const openHours = [];
          restaurant.open_days.split(",").forEach((day) => {
            const info = {
              day: day.toUpperCase(),
              from: restaurant.open_hour,
              until: restaurant.close_hour,
            };
            openHours.push(info);
          });
          restaurant.openHours = openHours;
          restaurants.push(restaurant);
        });
        state.restaurants = restaurants;
      })
      .addCase(getAllRestaurants.rejected, (state, action) => {
        serverError("restaurants");
        state.getAllRestaurantsStatus = "rejected";
      })

      .addCase(getRestaurantReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        const reviews = [...action.payload.response.data];
        reviews.forEach((review) => {
          const time = convertReviewTime(review.created_at);
          review.time = time;
          return review;
        });
        state.restaurantReviews = reviews;
      })
      .addCase(getRestaurantReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantReviews.rejected, (state, action) => {
        // serverError("restaurant reviews");
        state.isLoading = false;
      })

      .addCase(getNearbyRestaurants.fulfilled, (state, action) => {
        state.restaurantsNearby = action.payload.acceptedRestaurants;
        state.getNearbyRestaurantsStatus = "succeeded";
        state.spinnerActive = false;
      })
      .addCase(getNearbyRestaurants.rejected, (state) => {
        serverError("nearby restaurants");
        state.spinnerActive = false;
        state.getNearbyRestaurantsStatus = "rejected";
      });
  },
});

export const {
  setSelectedRestaurantId,
  activateSpinner,
  setUserAddress,
  addRestaurantInfoSecondaryImage,
  resetRestaurants,
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
