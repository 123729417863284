import Button from 'react-bootstrap/Button';

import './BurgerButton.css';
import { ReactComponent as BurgerButtonIcon } from '../../assets/burger-button/burger-button.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';

function BurgerButton({ rotated, ...props}) {
    return (
        <Button variant="outline-dark" className="custom-burger-button" {...props}>
            {
                !rotated ? 
                    <BurgerButtonIcon className="custom-burger-button-icon"/>
                :   <CloseIcon className="custom-burger-button-icon custom-close-icon"/>
            }
        </Button>
    );
}

export default BurgerButton;