import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { switchLang } from "../../redux/slices/langSlice";
import { displayNavSidebar } from "../../redux/slices/showModalSlice";
import {
  logoutFromAccount,
  automaticLogin,
  reset,
  activateToast,
} from "../../redux/slices/signInSlice";
import { NavLink, useNavigate } from "react-router-dom";

// i18n
import i18n from "i18next";
import { useTranslation } from "react-i18next";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/DropdownItem";
import Spinner from "react-bootstrap/Spinner";
import Image from "react-bootstrap/Image";

// Google auth
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../utils/firebase";

import "./NavContainer.css";
import Cart from "../../assets/navbar/cart.svg";
import CartDot from "../../assets/navbar/cart-dot.svg";
import User from "../../assets/navbar/user.svg";
import Home from "../../assets/icons/home-icon.svg";
import OurBranches from "../../assets/icons/our-branches-icon.svg";
import Bell from "../../assets/icons/bell-icon.svg";
import BellDot from "../../assets/icons/dot-bell-icon.svg";
import { ReactComponent as Dot } from "../../assets/icons/yellow-dot.svg";
// import { ReactComponent as LanguageIcon } from "../../assets/icons/language-icon.svg";
import { ReactComponent as EnglishIcon } from "../../assets/icons/flag-us.svg";
import { ReactComponent as FinnishIcon } from "../../assets/icons/flag-finland.svg";

import Icon from "../icon/Icon";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";

function NavContainer({ className }) {
  const total = Number(useSelector((state) => state.order.total)).toFixed(2);
  const notifications = useSelector(
    (state) => state.userInfo.notifications.length > 0
  );
  const myOrders = useSelector(
    (state) => state.userInfo.ongoingOrders.length > 0
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentLang = useSelector((state) => state.lang.lang);
  const loggedIn = useSelector((state) => state.signIn.loggedIn);
  const username = useSelector((state) => state.signIn.fullName.split(" ")[0]);
  const isLoading = useSelector((state) => state.signIn.isLoading);
  const message = useSelector((state) => state.signIn.message);
  const statusOk = useSelector((state) => state.signIn.statusOk);
  const operationName = useSelector((state) => state.signIn.operationName);
  const avatar = useSelector((state) => state.signIn.avatar);
  const toastActive = useSelector((state) => state.signIn.toastActive);
  const checkedItems = useSelector((state) => state.order.checkedItemsRef);
  const selectedSize = useSelector((state) => state.order.selectedSize);
  // const currentLang = useSelector((state) => state.lang.currentLang);

  const [user, loading] = useAuthState(auth);

  const [showConfimrationModal, setShowConfimrationModal] = useState(false);

  const handleLang = (e) => {
    dispatch(switchLang(e));
    i18n.changeLanguage(e);
    // dispatch(switchLang(e.target.id));
    // i18n.changeLanguage(e.target.id);
    document.documentElement.lang = JSON.parse(localStorage.getItem("lang"));
  };

  const hideNavSidebar = () => {
    dispatch(displayNavSidebar(false));
  };

  const proceedToLogout = () => {
    dispatch(logoutFromAccount());
    hideNavSidebar();
  };

  const handleLogout = () => {
    if (!loading) {
      signOut(auth);
      hideNavSidebar();
    }
    if (loggedIn) {
      // dispatch()
      if (checkedItems.length !== 0 || selectedSize !== "") {
        setShowConfimrationModal(true);
      } else {
        proceedToLogout();
      }
    }
  };

  const confirmationModalCancel = () => {
    setShowConfimrationModal(false);
  };

  const confirmationModalDiscard = () => {
    setShowConfimrationModal(false);
    proceedToLogout();
  };

  const handleLogin = () => {
    navigate("/login");
    hideNavSidebar();
  };

  useEffect(() => {
    dispatch(automaticLogin());
  }, []);

  // useEffect(() => {
  //   // if ((operationName === 'nav operation' && className === 'justify-content-end') || (operationName === 'nav operation' && className === 'flex-column custom-sidebar-nav')) {
  //   if (operationName === 'nav operation') {
  //     if (message !== '' && statusOk !== null && !toastActive) {
  //       if (statusOk) {
  //         dispatch(activateToast());
  //         toast.success(t(message));
  //         // formRef.current.reset();
  //         navigate(loggedIn ? -1 : '/');
  //       } else {
  //         toast.error(t(message));
  //       }
  //       setTimeout(() => {
  //         dispatch(reset());
  //       }, 3000);
  //     }
  //   }
  // }, [message, statusOk]);

  const AccountElement = () => {
    return (
      <span className="d-flex gap-1 custom-nav-link nav-link custom-account-element">
        {avatar ? (
          <Image src={avatar} roundedCircle className="custom-user-avatar" />
        ) : (
          <Icon src={User} />
        )}{" "}
        {username}
        {notifications || myOrders ? <Dot className="custom-dot" /> : null}
      </span>
    );
  };

  return (
    <Nav
      activeKey="/"
      className={`align-items-center gap-1 ${className} ${
        currentLang === "fi" ? "fi-adjustments" : ""
      }`}
    >
      {className === "justify-content-end" ? (
        <>
          <NavLink className="custom-nav-link nav-link" to="/">
            {t("Home")}
          </NavLink>
          <NavLink className="custom-nav-link nav-link" to="/our-branches">
            {t("Our Branches")}
          </NavLink>
          <NavLink
            className="custom-nav-link nav-link d-flex align-items-center"
            style={{ gap: "3px" }}
            to="/cart"
          >
            <Icon src={total > 0 ? CartDot : Cart} /> {t("Cart")}: €{total}
          </NavLink>
          {username || loggedIn ? (
            <DropdownButton
              title={<AccountElement />}
              className="custom-dropdown-menu custom-user-dropdown"
              bsPrefix="custom-element"
              id="nav-dropdown"
            >
              <DropdownItem eventKey="4.1" as="div">
                <NavLink to="/profile" className="custom-nav-link nav-link">
                  {t("Profile")}
                </NavLink>
              </DropdownItem>

              <DropdownItem eventKey="4.2" as="div">
                <NavLink to="/my-orders" className="custom-nav-link nav-link">
                  {t("My Orders")}
                  {myOrders ? <Dot className="custom-dot" /> : null}
                </NavLink>
              </DropdownItem>

              <DropdownItem eventKey="4.3" as="div">
                <NavLink
                  to="/notifications"
                  className="custom-nav-link nav-link"
                >
                  {t("Notifications")}
                  {notifications ? <Dot className="custom-dot" /> : null}
                </NavLink>
              </DropdownItem>

              <Dropdown.Divider />

              <DropdownItem
                eventKey="4.4"
                as="div"
                onClick={() => (loading ? null : handleLogout())}
              >
                <div className="custom-nav-link nav-link">{t("Logout")}</div>
              </DropdownItem>
            </DropdownButton>
          ) : (
            <Button
              variant="warning"
              className="custom-nav-link-button"
              onClick={() => navigate("/login")}
            >
              {loading || isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="custom-sign-in-spinner"
                  />
                </>
              ) : (
                <>{t("Sign in")}</>
              )}
            </Button>
          )}
          {i18n.language === "en" ? (
            <EnglishIcon
              className="custom-language-icon"
              onClick={() => handleLang("fi")}
            />
          ) : (
            <FinnishIcon
              className="custom-language-icon"
              onClick={() => handleLang("en")}
            />
          )}
        </>
      ) : (
        <>
          <div className="custom-nav-link-icons-container mb-1">
            <NavLink
              className="custom-icon-nav-link"
              to="/notifications"
              onClick={hideNavSidebar}
            >
              <Icon src={notifications ? BellDot : Bell} />
            </NavLink>
            <NavLink
              className="custom-icon-nav-link"
              to="/cart"
              onClick={hideNavSidebar}
            >
              <Icon src={total > 0 ? CartDot : Cart} />
            </NavLink>
            {i18n.language === "en" ? (
              <EnglishIcon
                className="custom-language-icon"
                onClick={() => handleLang("fi")}
              />
            ) : (
              <FinnishIcon
                className="custom-language-icon"
                onClick={() => handleLang("en")}
              />
            )}
          </div>
          <NavLink
            className="custom-nav-link nav-link sidebar-link"
            to="/"
            onClick={hideNavSidebar}
          >
            <img src={Home} alt="" width={20} height={20} />
            <span>{t("Home")}</span>
          </NavLink>
          <NavLink
            className="custom-nav-link nav-link sidebar-link"
            to="/our-branches"
            onClick={hideNavSidebar}
          >
            <img src={OurBranches} alt="" width={20} height={20} />
            <span>{t("Our Branches")}</span>
          </NavLink>
          {username || loggedIn ? (
            <DropdownButton
              title={<AccountElement />}
              bsPrefix="custom-element"
              className="custom-dropdown-menu custom-dropdown-menu-sidebar"
              id="nav-dropdown"
            >
              <DropdownItem eventKey="4.5" as="div">
                <NavLink
                  to="/profile"
                  className="custom-nav-link nav-link"
                  onClick={hideNavSidebar}
                >
                  {t("Profile")}
                </NavLink>
              </DropdownItem>

              <DropdownItem eventKey="4.6" as="div">
                <NavLink
                  to="/my-orders"
                  className="custom-nav-link nav-link"
                  onClick={hideNavSidebar}
                >
                  {t("My Orders")}
                  {myOrders ? <Dot className="custom-dot" /> : null}
                </NavLink>
              </DropdownItem>

              <DropdownItem eventKey="4.7" as="div">
                <NavLink
                  to="/notifications"
                  className="custom-nav-link nav-link"
                  onClick={hideNavSidebar}
                >
                  {t("Notifications")}
                  {notifications ? <Dot className="custom-dot" /> : null}
                </NavLink>
              </DropdownItem>

              <Dropdown.Divider />

              <DropdownItem eventKey="4.8" as="div" onClick={handleLogout}>
                <div className="custom-nav-link nav-link">{t("Logout")}</div>
              </DropdownItem>
            </DropdownButton>
          ) : (
            <Button
              variant="warning"
              className={`custom-nav-link-button custom-nav-link-button-sidebar ${
                currentLang === "fi" ? "fi-button-adjustments" : ""
              }`}
              onClick={handleLogin}
            >
              {loading || isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="custom-sign-in-spinner"
                  />
                  {t("Loading")}
                </>
              ) : (
                <>{t("Sign in")}</>
              )}
            </Button>
          )}
        </>
      )}
      <ConfirmationModal
        show={showConfimrationModal}
        message={"You have unsaved changes, do you want to discard them?"}
        handleConfirm={confirmationModalDiscard}
        handleCancel={confirmationModalCancel}
      />
    </Nav>
  );
}

export default NavContainer;
