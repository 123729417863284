import Image from "react-bootstrap/Image";

import "./Footer.css";
import FooterBackground from "../../assets/footer/footer-background.svg";
import LogoSection from "./logo-section/LogoSection";
import InfoSection from "./info-section/InfoSection";
import LegalInfoSection from "./legal-info-section/LegalInfoSection";

function Footer({ inSidebar = false }) {
  return (
    <footer
      className={`custom-footer ${inSidebar ? "custom-footer-sidebar" : ""}`}
    >
      <div
        className={`custom-contact-info ${
          inSidebar ? "custom-contact-info-sidebar" : ""
        }`}
      >
        {inSidebar ? null : <LogoSection />}
        <InfoSection inSidebar={inSidebar} />
        {inSidebar ? null : <LegalInfoSection />}
      </div>
      <Image
        src={FooterBackground}
        className="custom-footer-image"
        style={inSidebar ? { width: "45%" } : null}
        alt="vegetables"
        fluid
      />
    </footer>
  );
}

export default Footer;
